import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { format } from "date-fns";
import { isEmpty, keyBy, uniqBy } from "lodash";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import useGatekeeper from "../../../hooks/useGatekeeper";
import TextInput from "../../../ui-lib/components/TextInput";
import IconExport from "../../../ui-lib/icons/IconExport";
import { getFullName } from "../../../utils/UserUtils";
import getMergeState from "../../../utils/getMergeState";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import useGetMspChildUsersByParentTenantID from "../hooks/useGetMspChildUsersByParentTenantID";
import { parseArrayParam } from "./MspDashboardPage";
import MspUserAccessTable from "./MspUserAccessTable";
import MspUserActivityTable from "./MspUserActivityTable";

const activityCsvHeaders = [
  { key: "name", label: copyText.tableHeaderName },
  { key: "email", label: copyText.tableHeaderEmail },
  { key: "createdAt", label: copyText.tableHeaderCreatedAt },
  { key: "loginCount", label: copyText.tableHeaderLogins },
  { key: "lastLogin", label: copyText.tableHeaderLastLogin },
];

const accessCsvHeaders = [
  { key: "name", label: copyText.tableHeaderName },
  { key: "email", label: copyText.tableHeaderEmail },
  { key: "tenantName", label: copyText.tableHeaderTenantName },
  { key: "tenantID", label: copyText.tableHeaderTenantID },
];

type State = {
  accessUsersSearchTerm: string;
  activityUsersSearchTerm: string;
};

const initialState: State = {
  accessUsersSearchTerm: "",
  activityUsersSearchTerm: "",
};

interface Props {
  parentTenantID: string;
}

export default function MspUserViewContainer(props: Props) {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const [searchParamState] = useQueryParams({
    t_ids: withDefault(ArrayParam, []),
  });

  const tenantDocIDs = parseArrayParam(searchParamState.t_ids);

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const { data: _users = [], isLoading: isLoadingUsers } =
    useGetMspChildUsersByParentTenantID(props.parentTenantID);

  //
  // Render
  //

  const tenantsKeyedByID = keyBy(_mspTenants, "id");

  const users = _users.map((user) => {
    const fullName = getFullName(user);

    return {
      ...user,
      name: isEmpty(fullName.trim()) ? user.email : fullName,
      tenantName: tenantsKeyedByID[user.tenantID]?.name ?? "null",
    };
  });

  let activityUsers = uniqBy(users, "email");
  let accessUsers = users;

  if (state.activityUsersSearchTerm.length > 0) {
    activityUsers = activityUsers.filter((user) => {
      return [user.email, user.name]
        .join(" ")
        .toLowerCase()
        .includes(state.activityUsersSearchTerm.toLowerCase());
    });
  }

  if (state.accessUsersSearchTerm.length > 0) {
    accessUsers = accessUsers.filter((user) => {
      return [user.email, user.name, user.tenantName, user.tenantID]
        .join(" ")
        .toLowerCase()
        .includes(state.accessUsersSearchTerm.toLowerCase());
    });
  }

  if (tenantDocIDs.length > 0) {
    accessUsers = accessUsers.filter((user) => {
      return tenantDocIDs.includes(tenantsKeyedByID[user.tenantID].fsDocID);
    });
  }

  const isLoading = isLoadingTenants || isLoadingUsers;

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_sm}
      >
        <Text appearance="h3">{copyText.sectionHeaderUserActivity}</Text>
        <Flex>
          <Box marginRight={theme.space_sm} width={300}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              onChange={(event) =>
                mergeState({ activityUsersSearchTerm: event.target.value })
              }
              placeholder={copyText.searchInputPlaceholder}
              size="medium"
              value={state.activityUsersSearchTerm}
            />
          </Box>
          <CSVLink
            data={activityUsers}
            headers={activityCsvHeaders}
            filename={`msp-user-activity-${format(new Date(), "MM-dd-yyyy")}`}
          >
            <Button iconStart={<IconExport />} secondary size="small">
              {copyText.exportButtonLabel}
            </Button>
          </CSVLink>
        </Flex>
      </Flex>
      <MspUserActivityTable isLoading={isLoading} users={activityUsers} />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_sm}
      >
        <Text appearance="h3">{copyText.sectionHeaderUserAccess}</Text>
        <Flex>
          <Box marginRight={theme.space_sm} width={300}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              onChange={(event) =>
                mergeState({ accessUsersSearchTerm: event.target.value })
              }
              placeholder={copyText.searchInputPlaceholder}
              size="medium"
              value={state.accessUsersSearchTerm}
            />
          </Box>
          <CSVLink
            data={accessUsers}
            headers={accessCsvHeaders}
            filename={`msp-user-access-${format(new Date(), "MM-dd-yyyy")}`}
          >
            <Button iconStart={<IconExport />} secondary size="small">
              {copyText.exportButtonLabel}
            </Button>
          </CSVLink>
        </Flex>
      </Flex>
      <MspUserAccessTable isLoading={isLoading} users={accessUsers} />
    </Box>
  );
}
