import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import { RateRecommendationType } from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatCurrencyRounded } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import copyText from "../copyText";

type Recommendation = {
  id: string;
  tenantDocID: string;
  estimatedMonthlySavings: number;
  tenantName: string;
  term: string;
  type: RateRecommendationType;
};

type TableData = Recommendation & { hasTenantAccess: boolean };

const columnHelper = createColumnHelper<TableData>();

interface Props {
  isLoading: boolean;
  recommendations: Recommendation[];
  onInteraction: (interaction: MspRateRecommendationTable.Interaction) => void;
}

export function MspRateRecommendationTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        cell: (cell) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const type = cell.row.original.type;
          const hasAccess = cell.row.original.hasTenantAccess;

          let pathname = "";

          switch (type) {
            case RateRecommendationType.COMMITTED_USE_DISCOUNT:
              pathname = paths._commitmentDiscountGCP;
              break;
            case RateRecommendationType.RESERVED_INSTANCE_AWS:
            case RateRecommendationType.SAVINGS_PLAN_AWS:
              pathname = paths._commitmentDiscountAWS;
              break;
            case RateRecommendationType.RESERVED_INSTANCE_AZURE:
            case RateRecommendationType.SAVINGS_PLAN_AZURE:
              pathname = paths._commitmentDiscountAzure;
              break;
          }

          return hasAccess ? (
            <Link
              target="_blank"
              to={{
                pathname,
                search: `?tenant_id=${tenantDocID}`,
              }}
            >
              {cell.getValue()}
            </Link>
          ) : (
            <Text
              appearance="link"
              color={theme.link_color}
              onClick={() =>
                props.onInteraction({
                  type: MspRateRecommendationTable.INTERACTION_LINK_CLICKED,
                  tenantDocID,
                })
              }
            >
              {cell.getValue()}
            </Text>
          );
        },
        header: copyText.tableHeaderTenantName,
        size: 200,
      }),
      columnHelper.accessor("term", {
        cell: (cell) => copyText[`rateRecTermLabel_${cell.getValue()}`],
        header: copyText.tableHeaderTerm,
        size: 120,
      }),
      columnHelper.accessor("type", {
        cell: (cell) => copyText[`rateRecTypeLabel_${cell.getValue()}`],
        header: copyText.tableHeaderType,
        size: 180,
      }),
      columnHelper.accessor("estimatedMonthlySavings", {
        cell: (cell) => {
          return formatCurrencyRounded({
            currencyCode: "USD",
            number: cell.getValue(),
          });
        },
        header: copyText.tableHeaderPotentialSavingsMonthly,
        size: 175,
      }),
    ],
    [props.recommendations]
  );

  const data = props.recommendations.map((rec) => {
    const grant = authenticatedUser.grants.find(
      (grant) => grant.tenantDocID === rec.tenantDocID
    );

    return { ...rec, hasTenantAccess: !!grant };
  });

  return (
    <Table
      columns={columns}
      compact
      data={data}
      initialState={{
        sorting: [{ id: "estimatedMonthlySavings", desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspRateRecommendationTable.INTERACTION_LINK_CLICKED =
  "MspChildRateRecommendationTable.INTERACTION_LINK_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspRateRecommendationTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspRateRecommendationTable {
  export type Interaction = InteractionLinkClicked;
}

export default MspRateRecommendationTable;
